/* eslint-disable no-console */
import 'rc-dropdown/assets/index.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'url-search-params-polyfill';
import '../components/Accordion/Accordion.scss';
import '../components/PayCalcTimeline/PayCalcTimeline.scss';
import '../scss/calendar.scss';
import '../scss/main.scss';
import '../scss/nprogress.scss';
import '../scss/rc-dropdown.scss';
import '../scss/rc-tooltip.scss';
import '../tokens.css';

import React, { useEffect } from 'react';
import { initializeDatadogRum } from 'Datadog';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import createStore from 'store';
import { ThemeProvider } from '@ourtilt/tilt-ui-components';

import Toast from '@components/v2/Toast';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from 'contexts/auth-context';
import { NavigationProvider } from 'contexts/right-flyout-context';
import { FlagProvider } from 'contexts/flag-context';
import { UUIDProvider } from 'contexts/uuid-context';
import { useAppDispatch } from 'hooks/reduxHooks';
import { KustomerWrapper } from 'layouts/Pages';
import PendoInitializer from '../lib/pendo/pendo-initializer';
import PlanProvider from 'contexts/plan-context';

Router.events.on('routeChangeStart', (url, { shallow }) => {
  !shallow && NProgress.start();
});
Router.events.on('routeChangeComplete', (url: string) => {
  NProgress.done();
  window.scrollTo(0, 0);
});
Router.events.on('routeChangeError', () => NProgress.done());

const store = createStore(null);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// TODO(redux): remove this once we no longer need to access the redux store in transportLayer.
// Also, this is "Safe" because we cannot set reduxStore into global state until SSR completes.
function SafeSetReduxDispatchOnWindow() {
  const client = typeof window !== 'undefined';
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (client) {
      (window as any).__reduxDispatch__ = dispatch;
    }
  }, [client, dispatch]);
  return null;
}

function MyApp({ Component, pageProps, error }: any) {
  if (error) {
    return <h1>{error}</h1>;
  }

  // initialize DatadogRum in production
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      initializeDatadogRum();
    }
  }, []);

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <FlagProvider>
            <Provider store={store}>
              <GoogleOAuthProvider
                clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}
                onScriptLoadError={() => {
                  console.error('Failed to load Google OAuth. Reach out to Tilt support.');
                }}
              >
                <ThemeProvider>
                  <UUIDProvider>
                    <NavigationProvider>
                      <SafeSetReduxDispatchOnWindow />
                      <Head>
                        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                        <title>Tilt</title>
                      </Head>
                      <PlanProvider>
                        {getLayout(
                          <KustomerWrapper>
                            <PendoInitializer />
                            <Component {...pageProps} />
                          </KustomerWrapper>,
                        )}
                      </PlanProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </NavigationProvider>
                  </UUIDProvider>
                </ThemeProvider>
                <Toast />
              </GoogleOAuthProvider>
            </Provider>
          </FlagProvider>
        </AuthProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default MyApp;
