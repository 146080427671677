import useApi from '@api/transportLayer';
import { IUserVM } from '@types';
import { ACCESS_TOKEN, IS_AUTHENTICATED } from '@utils/auth-tokens';
import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { getLoginRedirectUrl } from 'router';
import Router, { useRouter } from 'next/router';
import { AxiosError, AxiosResponse } from 'axios';

export const SET_ME = 'SET_ME';

type Dispatch = (action) => void;
type State = { me: IUserVM; };
type AuthProviderProps = { children: React.ReactNode };

export const AuthContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

export function authReducer(state: State, action) {
  switch (action.type) {
    case SET_ME: {
      const payload = action.payload && { ...action.payload, ...action.payload.user };
      payload && delete payload['user'];
      return {
        ...state,
        me: payload || null,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthProvider({ children }: AuthProviderProps) {
  const router = useRouter();
  const [state, dispatch] = useReducer(authReducer, { me: null });

  const {
    data: me,
    refetch: getMe,
    error,
  } = useApi.Auth.getMe(
    {},
    {
      enabled: false,
    },
  );

  useEffect(() => {
    // TODO: remove this logic ~24 hours after release, this is a temporary patch for current sessions to migrate from local storage to httponly cookie
    const localStorageAccessToken = window?.localStorage?.getItem(ACCESS_TOKEN);
    const localCookieAccessToken = Cookies.get(ACCESS_TOKEN);
    // check for a local storage access token and a cookie access token that are not the same
    if (localStorageAccessToken && (!localCookieAccessToken || localCookieAccessToken !== localStorageAccessToken)) {
      // send the access token to the server via a POST request to write it as an http only cookie
      fetch('/api/auth/migrate-session/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Referer': window.location.href,
        },
        body: JSON.stringify({ access_token: localStorageAccessToken }),
      }).then(() => {
        window.localStorage?.removeItem(ACCESS_TOKEN);
        window.location.reload();
      });
    }
  }, [error, router.isReady]);

  // TODO: remove this once we can support server-side rendering and authentication
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted && router.isReady) {
      setIsMounted(true);
    }
    if (isMounted) {
      const isAuthenticated = Cookies.get(IS_AUTHENTICATED);
      if (isAuthenticated) {
        // authenticated, but no me data => fetch me data
        if (!state?.me && !me) {
          getMe();
        }
        // authenticated, and me data => set me data on context
        if (!state?.me && me) {
          dispatch({ type: SET_ME, payload: me });
        }
      }
    }
  }, [isMounted, me, state, router.isReady]);

  if (!isMounted) {
    return null;
  }

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
}

function useIdentity() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useIdentity must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useIdentity };
