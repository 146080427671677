const BASE_API_PATH = '/api';
// Special Case: We need to add/remove the JWT after the login/logout request.
const BASE_AUTH_API_PATH = `${BASE_API_PATH}/auth`;
const LEGACY_LOGIN_URL_PATH = `${BASE_AUTH_API_PATH}/legacy-login/`;
const LOGOUT_URL_PATH = `${BASE_AUTH_API_PATH}/legacy-logout/`;
const IMPERSONATE_URL_PATH = `${BASE_AUTH_API_PATH}/admin/user/impersonate/`;
const REFRESH_TOKEN_URL_PATH = `${BASE_AUTH_API_PATH}/user/refresh-token/`;
const VERIFY_2FA_URL_PATH = `${BASE_AUTH_API_PATH}/2fa/verify/`;
const CONFIRM_ACTIVATE_2FA_URL_PATH_REGEX = /^\/api\/auth\/2fa\/.+\/activate\/confirm\/$/;

// React-Query Automatic Retry Config
// The HTTP status codes listed are considered potentially intermittent.
// Everything else is very likely idempotent, so retrying is not needed.
const HTTP_RETRY_CODES = [500, 502, 504];
const DEFAULT_RETRY_LIMIT = 3;

export {
  BASE_API_PATH,
  BASE_AUTH_API_PATH,
  LEGACY_LOGIN_URL_PATH,
  LOGOUT_URL_PATH,
  IMPERSONATE_URL_PATH,
  REFRESH_TOKEN_URL_PATH,
  VERIFY_2FA_URL_PATH,
  CONFIRM_ACTIVATE_2FA_URL_PATH_REGEX,
  HTTP_RETRY_CODES,
  DEFAULT_RETRY_LIMIT,
};
