import { DjangoList, IBenefitResultVM, ICompanyVM, IFileVM, IUUIDsVM, IUserAggregatedData } from '@types';
import { useQueryClient as _useQueryClient } from '@tanstack/react-query';
import Admin from './admin';
import Auth from './auth';
import { BASE_API_PATH } from './constants';
import EE from './employee';
import HR from './hr';
import QueryKeys from './queryKeys';
import { query, mutate } from './request-lib';

// NOTE: just a thin wrapper to avoid importing directly from react-query in React components.
export function useQueryClient() {
  return _useQueryClient();
}

const Benefit = {
  Result: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IBenefitResultVM>>(
        'get',
        `${BASE_API_PATH}/benefits/results/?leave=:leaveId`,
        params,
        options,
        {},
        QueryKeys.BenefitResults,
      ),
  },
  Rule: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IBenefitResultVM>>(
        'get',
        `${BASE_API_PATH}/benefits/rules/templates/`,
        params,
        options,
        {},
        QueryKeys.BenefitResults,
      ),
  },
};

const Company = {
  getOnePublic: (params = {}, options = {}) =>
    query<ICompanyVM>('get', `${BASE_API_PATH}/legacy/companies/public/`, params, {
      useAccessToken: false,
      ...options,
    }),
};

const Flags = {
  getFlags: (params = {}, options = {}) =>
    query<Record<string, boolean>>('get', `${BASE_API_PATH}/flags/me/`, params, options),
  getCompanyFlags: (params = {}, options = {}) =>
    query<Record<string, boolean>>('get', `${BASE_API_PATH}/flags/company/:companyId/`, params, options),
  getUserFlags: (params = {}, options = {}) =>
    query<Record<string, boolean>>('get', `${BASE_API_PATH}/flags/user/:userId/`, params, options),
};

const Files = {
  getMany: (params = {}, options = {}) =>
    query<DjangoList<IFileVM>>('get', `${BASE_API_PATH}/legacy/files/`, params, options),
  upload: (options = {}) => mutate('post', `${BASE_API_PATH}/legacy/files/`, { isUpload: true, ...options }),
  delete: (options = {}) => mutate('delete', `${BASE_API_PATH}/legacy/files/:id/`, options),
};

const MaintenanceMode = {
  getMaintenanceMode: (params = {}, options = {}) =>
    query<any>('get', `${BASE_API_PATH}/maintenance/`, params, { useAccessToken: false, ...options }),
};

const UUIDs = {
  getPlatformUUIDs: (params = {}, options = {}) =>
    query<IUUIDsVM>('get', `${BASE_API_PATH}/uuids/`, params, { enabled: false, ...options }),
};

const UserAggregatedData = {
  getUserAggregatedData: (params = {}, options = {}) =>
    query<IUserAggregatedData>('get', `${BASE_API_PATH}/legacy/users/:userId/aggregated-data/`, params, options),
};

const useApi = { Admin, Auth, Benefit, Company, EE, Flags, Hr: HR, MaintenanceMode, UUIDs, Files, UserAggregatedData };

export default useApi;
