import { IUserVM, LoginResponse } from '@types';

import {
  BASE_API_PATH,
  BASE_AUTH_API_PATH,
  IMPERSONATE_URL_PATH,
  REFRESH_TOKEN_URL_PATH,
  VERIFY_2FA_URL_PATH,
} from './constants';
import { mutate, query } from './request-lib';

export const AUTH_PATHS = {
  BASE: BASE_AUTH_API_PATH,
  PUBLIC: `${BASE_AUTH_API_PATH}/public`,
};

const Auth = {
  activateAccount: (options = {}) =>
    mutate('post', `${AUTH_PATHS.PUBLIC}/activate/`, { useAccessToken: false, ...options }),
  getEnv: (params = {}, options = {}) => query<Record<string, string>>('get', `${BASE_API_PATH}/env/`, params, options),
  changePassword: (options = {}) => mutate('put', `${AUTH_PATHS.BASE}/user/change-password/`, options),
  getMe: (params = {}, options = {}) => query<IUserVM>('get', `${AUTH_PATHS.BASE}/user/me/`, params, options),
  userSubdomain: (options = {}) =>
    mutate('post', `${AUTH_PATHS.PUBLIC}/user/subdomain/`, { useAccessToken: false, ...options }),
  updateMe: (options = {}) => mutate('patch', `${AUTH_PATHS.BASE}/user/me/`, { isUpload: true, ...options }),
  impersonate: (options = {}) => mutate('post', IMPERSONATE_URL_PATH, options),
  installWithRippling: (options = {}) => mutate('post', `${AUTH_PATHS.BASE}/rippling-install/`, options),
  login: (options = {}) =>
    mutate<LoginResponse>('post', `${AUTH_PATHS.BASE}/legacy-login/`, { useAccessToken: false, ...options }),
  loginGoogle: (options = {}) =>
    mutate<LoginResponse>('post', `${AUTH_PATHS.BASE}/google/login/`, { useAccessToken: false, ...options }),
  ssoLoginRequest: (options = {}) =>
    mutate('post', `${AUTH_PATHS.BASE}/sso/:companyUuid/login/`, { useAccessToken: false, ...options }),
  logout: (options = {}) => mutate('post', `${AUTH_PATHS.BASE}/legacy-logout/`, options),
  refreshToken: (options = {}) => mutate('post', REFRESH_TOKEN_URL_PATH, options),
  forgotPassword: (options = {}) =>
    mutate('post', `${AUTH_PATHS.PUBLIC}/forgot-password/`, { useAccessToken: false, ...options }),
  setNewPassword: (options = {}) =>
    mutate('put', `${AUTH_PATHS.PUBLIC}/reset-password/`, { useAccessToken: false, ...options }),
  checkSubdomain: (options = {}) =>
    mutate('post', `${AUTH_PATHS.PUBLIC}/subdomain/`, { useAccessToken: false, ...options }),
  TwoFactorAuth: {
    enable: (options = {}) => mutate('post', `${AUTH_PATHS.BASE}/2fa/:methodName/activate/`, options),
    confirmEnable: (options = {}) => mutate('post', `${AUTH_PATHS.BASE}/2fa/:methodName/activate/confirm/`, options),
    requestSMS: (options = {}) => mutate('post', `${AUTH_PATHS.BASE}/2fa/code/request/`, options),
    verify: (options = {}) => mutate('post', VERIFY_2FA_URL_PATH, options),
    dismiss2faSetup: (options = {}) => mutate<IUserVM>('patch', `${BASE_API_PATH}/legacy/users/:userId/`, options),
  },
};

export default Auth;
