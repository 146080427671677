interface DocumentCheckResult {
  type: number;
  required: boolean;
  neededAction: boolean;
}

interface ValidationError {
    error: string;
    type: "document_generation" | "document_removal";
}

interface ValidationDetails {
  startedAt: string;
  completedAt: string;
  existingDocs: DocumentCheckResult[];
  addedRequired: number[];
  removedAutomated: number[];
  errors?: Record<number, ValidationError>;
}

export interface ILeaveValidation {
  status: number;
  details: ValidationDetails;
}

export interface ILeaveValidationResultVM {
  id: number;
  leaveId: number;
  status: number;
  details: ValidationDetails;
}

export enum ELeaveValidationStatus {
  IN_PROGRESS = 1,
  SUCCESS = 2,
  FAILURE = 3,
  PARTIAL = 4,
  ERROR = 5,
}

export enum FMLADocumentType {
  "FMLA Designation Notice" = 5,
  "FMLA Caregiving Med Cert" = 7,
  "FMLA Med Cert" = 9,
  "Notice of Eligibility and Rights and Responsibilities" = 10
}

export const LeaveValidationStatusDisplay = {
  [ELeaveValidationStatus.IN_PROGRESS]: 'In Progress',
  [ELeaveValidationStatus.SUCCESS]: 'Success',
  [ELeaveValidationStatus.FAILURE]: 'Failure',
  [ELeaveValidationStatus.PARTIAL]: 'Partial',
  [ELeaveValidationStatus.ERROR]: 'Error',
};
