import React, { createContext, useContext } from 'react';
import useApi from '@api/transportLayer';

export interface IFlagVMV2 {
  [flagName: string]: {
    everyone: boolean;
    companies: number[];
    users: number[];
  };
}

interface FlagProviderProps {
  children: React.ReactNode;
}

export const FlagContext = createContext<IFlagVMV2 | null>(null);

export const FlagProvider: React.FC<FlagProviderProps> = ({ children }: FlagProviderProps) => {
  const flags = useApi.Flags.getFlags(
    {},
    {
      staleTime: 20 * 60 * 1000, // Cache for 20 minutes
    },
  );

  if (flags.isLoading) {
    return null; // TODO add loading state
  }

  if (flags.isError) {
    return null; // TODO add error state
  }

  return <FlagContext.Provider value={flags.data as unknown as IFlagVMV2}>{children}</FlagContext.Provider>;
};

export const useFlagContext = () => {
  const flags = useContext(FlagContext);

  if (!flags) {
    throw new Error('FlagContext: no value provided');
  }

  const hasFlag = ({
    flagName,
    companyId,
    userId,
  }: {
    flagName: string;
    companyId?: number;
    userId?: number;
  }): boolean => {
    if (!flags[flagName]) return false;
    else if (flags[flagName]?.everyone) return true;
    else if (companyId && flags[flagName]?.companies.includes(companyId)) return true;
    else if (userId && flags[flagName]?.users.includes(userId)) return true;
    return false;
  };

  return { flags, hasFlag };
};
