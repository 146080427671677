import { getSubdomain } from '../utils/subdomain';

const ACCESS_TOKEN = 'tilt-access-token';
const REFRESH_TOKEN = 'tilt-refresh-token';
const IMPERSONATE_ACCESS_TOKEN = 'tilt-impersonate-token';
const IMPERSONATE_SUBDOMAIN = 'tilt-impersonate-subdomain';
const DEVICE_UID = 'deviceUID'; // can we change this w/o breaking MFA?
const TWO_FACTOR_IGNORE_PAGE = '2fa-ignore-page';
const IS_AUTHENTICATED = 'is-authenticated';
const TWO_FACTOR_EPHEMERAL_TOKEN = '2fa-ephemeral-token';

/** @deprecated tokens are no longer stored in local storage */
export function getAccessToken() {
  if (typeof window !== 'undefined') {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    const impersonateToken = window.localStorage.getItem(IMPERSONATE_ACCESS_TOKEN);
    const impersonateSubdomain = window.localStorage.getItem(IMPERSONATE_SUBDOMAIN);
    if (!token && impersonateToken && impersonateSubdomain === getSubdomain()) {
      return impersonateToken;
    } else {
      return token;
    }
  }
}

/** @deprecated redux-sagas and generator functions are no longer used */
export function get2faIgnorePage() {
  return typeof window !== 'undefined' && window.localStorage.getItem(TWO_FACTOR_IGNORE_PAGE);
}

/** @deprecated redux-sagas and generator functions are no longer used */
export function set2faIgnorePage(value?: string) {
  if (typeof window !== 'undefined') {
    if (!value) {
      window.localStorage.removeItem(TWO_FACTOR_IGNORE_PAGE);
    } else {
      window.localStorage.setItem(TWO_FACTOR_IGNORE_PAGE, value);
    }
  }
}

export function getDeviceUID() {
  return typeof window !== 'undefined' && window.localStorage.getItem(DEVICE_UID);
}

export function saveDeviceUID(deviceUID?: string) {
  if (deviceUID && typeof window !== 'undefined') {
    window.localStorage.setItem(DEVICE_UID, deviceUID);
  }
}

/** @deprecated tokens are no longer stored in local storage */
export function saveImpersonateAccessToken(accessToken?: string) {
  if (typeof window !== 'undefined') {
    if (!accessToken) {
      window.localStorage.removeItem(IMPERSONATE_ACCESS_TOKEN);
      window.localStorage.removeItem(IMPERSONATE_SUBDOMAIN);
    } else {
      window.localStorage.setItem(IMPERSONATE_ACCESS_TOKEN, accessToken);
      window.localStorage.setItem(IMPERSONATE_SUBDOMAIN, getSubdomain());
    }
  }
}

/** @deprecated tokens are no longer stored in local storage */
export function saveRefreshToken(refreshToken?: string) {
  if (typeof window !== 'undefined') {
    if (!refreshToken) {
      window.localStorage.removeItem(REFRESH_TOKEN);
    } else {
      window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }
  }
}

/** @deprecated tokens are no longer stored in local storage */
export function saveAccessToken(accessToken?: string) {
  // save to local storage for backwards compatibility
  if (typeof window !== 'undefined') {
    if (!accessToken) {
      window.localStorage.removeItem(ACCESS_TOKEN);
    } else {
      window.localStorage.setItem(ACCESS_TOKEN, accessToken);
    }
  }
}

export {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  IMPERSONATE_ACCESS_TOKEN,
  IMPERSONATE_SUBDOMAIN,
  DEVICE_UID,
  TWO_FACTOR_IGNORE_PAGE,
  IS_AUTHENTICATED,
  TWO_FACTOR_EPHEMERAL_TOKEN,
};
